import React from "react"
import { Helmet } from "react-helmet"

import Layout from "layouts/default"
import Header from "components/components/header"
import BlogHeader from "components/blog/blog-header"
import Posts from "components/blog/posts"

const BlogPage = () => (
  <Layout>
    <Helmet>
      <title>Netspeak Games Blog</title>
    </Helmet>
    <Header />
    <BlogHeader />
    <Posts />
  </Layout>
)

export default BlogPage
